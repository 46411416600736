html, body {
  margin:  0;
  padding: 0;
}

* {
  margin: 0;
}

table {
  border: 5px solid #26466F;
  width: inherit;
}
  
th {
  /* border-bottom: 1px solid black; */
  width: 25%; /* 4 items */
}

/* Tables */
.Table-Normal {
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  width: inherit;
  height: auto;
  border-collapse: collapse;
  text-align: center;
  font-family: Arial;
}

.Table-Normal thead input {
  width: inherit;
  border-collapse: collapse;
  border-bottom: 0px solid black;
}

.Table-Normal thead tr {
  width: inherit;
  background-color: #26466F;
  color: #8BDEFF;
  font-weight: bold;
}

.Table-Normal tr {
  margin: 0;
  padding: 0;
  border: 0;
  border: 1px solid black;
  width: inherit;
}

.Table-Normal tr td {
  margin: 0;
  padding: 4px 8px;
  border: 0;
  border: 1px solid black;
  
}

.Table-Normal tbody tr:nth-child(even) {
  background-color: #8BDEFF;
}

.table-wrapper input {
  padding: 5px 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  width: 75%;
  height: auto;
  text-align: left;
  border: 1px solid #26466F;
}

.horizontal_right button {
  padding: 5px 10px;
  margin-left: 5%;
  margin-bottom: 5px;
  margin-top: 5px;
  height: auto;
}

.time_cell {
  color: black;
}

.table_select {
  display: flex;
  padding: inherit;
  justify-content: right;
}
  
td {
  text-align: center;
  padding: 10px 0;
}

.table-wrapper {
  margin-top: 5px;
  margin-bottom: 0px;
  width: 95%;
}

/* Navbar container */
.navbar {
  overflow: hidden;
  width: 100%;
  background-color: #26466F;
  font-family: Arial;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 16px;
  color: #8BDEFF;
  text-align: center;
  padding: 14px 13px;
  text-decoration: none;
}

.horizontal_group {
  display: inline-block;
  font-size: auto;
  width: inherit;
  margin: inherit;
  margin-bottom: 10px;
  margin-left: 5%;
}

.horizontal_left {
  font-size: 12px;
  display: inline-block;
  align-content: left;
}

.horizontal_right {
  display: inline;
  float: right;
}

select.horizontal_right {
  display: inline;
  font-size: 12px;
  border-radius: 5px;
}

p.horizontal_right {
  font-size: 12px;
  float: right;
  margin-right: 3px;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #8BDEFF;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: red;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #26466F;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #8BDEFF;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.pop_up {
  display: block;
}

.box {
  background-color: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box a {
  display: inline-block;
  background-color: #fff;
  padding: 15px;
  border-radius: 3px;
}
.modal {
  align-items: center;
  display: flex;
  justify-content: center; 
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(254, 126, 126, 0.7);
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
}
.content {
  position: absolute;
  background: white;
  width: 400px;
  padding: 1em 2em;
  border-radius: 4px;
} 
.modal:target {
  visibility: visible;
  opacity: 1;
}
.box-close {
  position: absolute;
  top: 0;
  right: 15px;
  color: #fe0606;
  text-decoration: none;
  font-size: 30px;
}

.top-row {
  display: flex;
  flex-direction: row;
  margin-left: 4%;
  width: inherit;
  font-family: Arial;
}

#left {
  display: flex;
  width: 30%;
  justify-content: flex-start;
}

#right {
  display: flex;
  width: 70%;
  justify-content: flex-end;
}

#left input {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

#left select {
  display: flex;
  width: 50%;
}

.activityIndicator {
  display: flex;
  padding: 5px 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.top-row button {
  padding: 5px 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.top-row select {
  padding: 5px 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.top-row input {
  width: 50%;
  height: auto;
  padding: 5px 5px;
  margin-bottom: 5px;
}

.hidden-button {
  display: none;
}

.activity_indicator {
  display: none;
}

.ag-theme-quartz {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(139, 222, 255);;
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}

.ag-theme-quartz-dark {
  --ag-foreground-color: rgb(38, 70, 111);
  --ag-background-color: rgb(139, 222, 255);
  --ag-header-foreground-color: rgb(139, 222, 255);
  --ag-header-background-color: rgb(139, 222, 255);
  --ag-odd-row-background-color: rgb(139, 222, 255);;
  --ag-header-column-resize-handle-color: rgb(139, 222, 255);;

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}